import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { MatDialog } from '@angular/material/dialog';
import { ButtonRendererComponent } from 'src/app/components/cell-renderers';
import Litepicker from 'litepicker';
import { DateTime } from 'litepicker/dist/types/datetime';
import Swal from 'sweetalert2';

import {
  AppAlerts,
  FormValidators
} from 'src/app/constants';

import {
  IKeyValuePair,
  OrderChannel
} from 'src/app/domain/kvp';

import {
  Customer,
  Item,
  OrderItem,
  ShipTo,
  ShipVia
} from 'src/app/domain/models';

import {
  AddShipToRequest,
  ItemAvailabilityRequest,
  ItemsRequest,
  MinimumRequiredDateRequest,
  ShipToRequest,
  SubmitOrderRequest
} from 'src/app/domain/requestresponseobjects';

import {
  AddItemToOrderDialog,
  CreateShiptoDialog
} from 'src/app/components/dialogs';

import {
  IdentityService,
  OrderService,
  UtilityService
} from 'src/app/services';
import { isNumber } from 'util';

@Component({
  selector: 'app-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.scss']
})
export class OrderEntryComponent implements AfterViewInit, OnDestroy, OnInit {

  private _customers: Customer[];
  private _datepickerOrder: Litepicker;
  private _datepickerRequired: Litepicker;
  private _gridApi: any;
  private _parts: Item[];
  private _subCustomerAutoTrigger: Subscription;
  private _subSelectedCustomer: Subscription;
  private _subSelectedShipTo: Subscription;

  public columnDefs = [];
  public filteredCustomers: Observable<Customer[]>;
  public form: FormGroup;
  public formSubmitted: boolean = false;
  public frameworkComponents: any = { buttonRenderer: ButtonRendererComponent };
  public isDealer: boolean = this.identityService.userIsInRole('Dealer');
  public orderChannels: IKeyValuePair<string>[] = OrderChannel.collection;
  public orderDate: Date = new Date();
  public orderItems: OrderItem[] = [];
  public requiredDate: Date = new Date();
  public shipTos: ShipTo[];
  public shipVias: ShipVia[];
  public submitAttempted: boolean = false;

  @ViewChild('customerAutoInput', { read: MatAutocompleteTrigger }) customerAutoTrigger: MatAutocompleteTrigger;

  public get f(): any {
    return this.form.controls;
  }

  constructor(
    private dialog: MatDialog,
    private identityService: IdentityService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private utilityService: UtilityService) {
      this.form = this.formBuilder.group({
        channel: 'SP',
        comments: null,
        ownerContact: null,
        ownerEmail: [null, Validators.compose([Validators.email])],
        ownerPhone: null,
        partialShipment: true,
        poNumber: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9-_\.\/]+$/)]],
        selectedCustomer: [null, Validators.compose([Validators.required, this._autocompleteValidator.bind(this)])],
        selectedShipTo: [null, Validators.required],
        selectedShipVia: 'Best Way'
      });
    }

  ngAfterViewInit() {
    if (!this.isDealer)
      this._subCustomerAutoTrigger = this.customerAutoTrigger.panelClosingActions.subscribe(() => {
        if (this.customerAutoTrigger.activeOption)
          this.f.selectedCustomer.setValue(this.customerAutoTrigger.activeOption.value);
      });
  }
  ngOnDestroy() {
    if (this._subCustomerAutoTrigger)
      this._subCustomerAutoTrigger.unsubscribe();
    if (this._subSelectedCustomer)
      this._subSelectedCustomer.unsubscribe();
    if (this._subSelectedShipTo)
      this._subSelectedShipTo.unsubscribe();
  }
  ngOnInit() {
    this._setColumnDefs();

    this._subSelectedCustomer = this.f.selectedCustomer.valueChanges
      .subscribe((val) => {
        if (this.f.selectedCustomer.valid) {
          this._loadShipTos(null);
          this._loadItems();
        }
      });
    this._subSelectedShipTo = this.f.selectedShipTo.valueChanges
      .subscribe((val) => {
        if (val === 'ADD_NEW') {
          let ref = this.dialog.open(
            CreateShiptoDialog,
            {
              width: '100vw',
              data: {
                parts: this._parts
              }
            });
          ref.afterClosed()
            .subscribe(result => {
              if (!result)
                this.f.selectedShipTo
                  .setValue(this.shipTos.filter(st => { return st.shipToType === 'CUSTOMER'; })[0].ship);
              else
                this.orderService.addShipTo$(new AddShipToRequest().deserialize({
                  attn: result.attn,
                  city: result.city,
                  country: result.country.country,
                  customer: this.f.selectedCustomer.value.customer,
                  line1: result.line1,
                  line2: result.line2,
                  line3: result.line3,
                  name: result.name,
                  phone: result.phone,
                  state: result.state.state,
                  zip: result.zip
                }))
                  .subscribe(response => {
                    this._loadShipTos(response.ship);
                  });
            })
        }
      });

    this._loadMinimumOrderRequiredDate();
    this._loadCustomers();
    this._loadShipVias();
  }

  private _autocompleteValidator(control: AbstractControl) {
    return control.value && typeof control.value === 'string'
      ? { autocomplete: true }
      : null;
  }

  private _defaultCustomer(): Customer {
    if (this._customers.length === 0)
      return null;

    var customerMatch = this._customers.filter(c => {
      return c.customer.startsWith('CS');
    });

    return (customerMatch && customerMatch.length && customerMatch.length > 0)
      ? customerMatch[0]
      : this._customers[0];
  }

  private _filterCustomers(match: any) : Customer[] {
    // ensure string
    match += '';

    // ignore until length 3
    if (match.length < 3)
      return [];

    // initialize priority arrays
    let priority1Matches: Customer[] = [];
    let priority2Matches: Customer[] = [];
    let priority3Matches: Customer[] = [];
    let priority4Matches: Customer[] = [];

    // iterate
    for (var i = 0; i < this._customers.length; i++) {
      // priority 1: customer startsWith
      if (this._customers[i].customer.toLowerCase().startsWith(match.toLowerCase()))
        priority1Matches.push(this._customers[i]);
      // priority 2: customer contains
      else if (this._customers[i].customer.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority2Matches.push(this._customers[i]);
      // priority 3: name startsWith
      else if (this._customers[i].name.toLowerCase().startsWith(match.toLowerCase()))
        priority3Matches.push(this._customers[i]);
      // priority 4: name containas
      else if (this._customers[i].name.toLowerCase().indexOf(match.toLowerCase()) > -1)
        priority4Matches.push(this._customers[i]);
    }

    // concat, return
    return priority1Matches.concat(priority2Matches).concat(priority3Matches).concat(priority4Matches);
  }

  private _loadCustomers(): void {
    if (this.isDealer) {
      this._customers = this.identityService.getDealerCustomers();
      this.filteredCustomers = of(this._customers);
      this.f.selectedCustomer.setValue(this._defaultCustomer());
    }
    else {
      this.filteredCustomers = this.f.selectedCustomer.valueChanges
        .pipe(
          map(val => this._filterCustomers(val)));

      this.identityService.customers$()
        .subscribe(response => {
          this._customers = response.customers;
        });
    }
  }
  private _loadItems(): void {

    this.orderService.items$(new ItemsRequest().deserialize({ customer: this.f.selectedCustomer.value.customer, pricelist: this.identityService.getPricelist() }))
      .subscribe(response => {
        this._parts = response.items;
      })
  }
  private _loadMinimumOrderRequiredDate(): void {
    this.orderService.minimumRequiredDate$(new MinimumRequiredDateRequest().deserialize({ orderDate: this.orderDate }))
      .subscribe(response => {
        this.requiredDate = new Date(response.requiredDate);
        this._setDatePickers();
      });
  }
  private _loadShipTos(shipToSelect): void {
    this.orderService.shipTos$(new ShipToRequest().deserialize({ customer: this.f.selectedCustomer.value.customer }))
      .subscribe(response => {
        this.shipTos = response.shipTos;
        this.f.selectedShipTo
          .setValue(
            shipToSelect
              ? shipToSelect
              : this.shipTos.filter(st => { return st.shipToType === 'CUSTOMER'; })[0].ship);
      });
  }
  private _loadShipVias(): void {
    this.orderService.shipVias$()
      .subscribe(response => {
        this.shipVias = response.shipVias;
      });
  }

  private _setColumnDefs(): void {
    this.columnDefs = [{
      // part
      field: 'part',
      headerName: 'Part',
      filter: false,
      flex: 2
    }, {
      // description
      field: 'description',
      headerName: 'Description',
      filter: false,
      flex: 4
    }, {
      // dueDate
      field: 'dueDate',
      headerName: 'Due Date',
      filter: false,
      flex: 2,
      valueFormatter: this.utilityService.gridFormatterDate
    }, {
      // uom
      field: 'uom',
      headerName: 'UoM',
      filter: false,
      flex: 2
    }, {
      // unit price
      field: 'unitPrice',
      headerName: 'Unit Price',
      filter: false,
      flex: 2,
      valueFormatter: this.utilityService.gridFormatterCurrency
    }, {
      // qtyAvail
      field: 'qtyAvailable',
      headerName: 'Qty Available',
      filter: false,
      flex: 2
    }, {
      // qtyOrdered
      editable: true,
      field: 'qtyOrdered',
      headerName: 'Qty Ordered',
      filter: false,
      flex: 2
    }, {
      // line price
      field: 'linePrice',
      headerName: 'Line Price',
      filter: false,
      flex: 2,
      valueFormatter: this.utilityService.gridFormatterCurrency
    }, {
      // delete btn
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.rowDelete.bind(this),
        class: 'grid-button-r',
        icon: 'clear'
      },
      headerName: '',
      filter: false,
      flex: 1
    }];
  }
  private _setDatePickers(): void {
    this._datepickerOrder = new Litepicker({
      element: document.getElementById('orderDate'),
      format: 'MM/DD/YYYY',
      setup: (picker) => {
        picker.on('show', () => {
          if (this.isDealer)
            picker.hide();
        })
      },
      singleMode: true,
      startDate: this.orderDate
    });
    this._datepickerRequired = new Litepicker({
      element: document.getElementById('requiredDate'),
      format: 'MM/DD/YYYY',
      minDate: this.requiredDate,
      setup: (picker) => {
        picker.on('selected', (d: DateTime) => {
          // iterate items, update due dates
          this.orderItems.forEach(item => {
            if (item.dueDate.toDateString() == this.requiredDate.toDateString() && item.qtyOrdered <= item.qtyAvailable)
              item.dueDate = d.toJSDate();
          });
          // refresh grid
          this._gridApi.refreshCells();
          // edit order req'd date
          this.requiredDate = d.toJSDate();
        })
      },
      singleMode: true,
      startDate: this.requiredDate
    });
  }

  private _validateForm(): void {
    Object.keys(this.f).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl)
        control.markAsTouched({ onlySelf: true });
    });
  }

  public addItemEnabled(): boolean {
    return (this._parts && this._parts.length && this._parts.length > 0);
  }

  public displayCustomerWith(customer: Customer): string | undefined {
    return customer
      ? `${customer.customer} (${customer.name})`
      : undefined;
  }

  public getSelectedCustomerError(): string {
    return this.f.selectedCustomer.hasError('required')
      ? FormValidators.required
      : this.f.selectedCustomer.hasError('autocomplete')
        ? FormValidators.autocomplete
        : FormValidators.none;
  }
  public getOrderItemsError(): string {
    return this.orderItemsHasError()
      ? FormValidators.orderItems
      : FormValidators.none;
  }
  public getOwnerEmailError(): string {
    return this.f.ownerEmail.hasError('email')
      ? FormValidators.email
      : FormValidators.none;
  }
  public getPoNumberError(): string {
    return this.f.poNumber.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }
  public getSelectedShipToError(): string {
    return this.f.selectedShipTo.hasError('required')
      ? FormValidators.required
      : FormValidators.none;
  }

  public onCellValueChanged(ev: any) {
    if (ev.data.qtyOrdered > ev.data.qtyAvailable) {
      this.orderService.itemAvailability$(new ItemAvailabilityRequest().deserialize({ part: ev.data.part, qtyOrdered: Number(ev.data.qtyOrdered) }))
        .subscribe(response => {
          if (new Date(response.availableDate) > new Date(ev.data.dueDate)) {
            ev.data.dueDate = new Date(response.availableDate);
            this._gridApi.refreshCells();
          }
        });
    }
      else {
        ev.data.dueDate = new Date(this.requiredDate);
        this._gridApi.refreshCells();
      }
  }
  public onGridReady(ev: any): void {
    this._gridApi = ev.api;
  }

  public openAddItemModal(): void {
    let ref = this.dialog.open(
      AddItemToOrderDialog,
      {
        width: '100vw',
        data: {
          parts: this._parts
        }
      });

    ref.afterClosed()
      .subscribe(result => {
        if (!result)
          return;

        const orderItem = new OrderItem().deserialize({
          description: result.selectedPart.description,
          part: result.selectedPart.part,
          qtyAvailable: result.selectedPart.qtyAvailable,
          qtyOrdered: Number(result.qtyToOrder),
          unitPrice: result.selectedPart.price,
          uom: result.selectedPart.uom
        });

        this.orderService.itemAvailability$(new ItemAvailabilityRequest().deserialize({ part: orderItem.part, qtyOrdered: orderItem.qtyOrdered }))
          .subscribe(response => {
            orderItem.dueDate = orderItem.qtyOrdered > orderItem.qtyAvailable
              ? new Date(response.availableDate)
              : new Date(this.requiredDate);

            this.orderItems.unshift(orderItem);
            this._gridApi.applyTransaction({
              add: [ orderItem ],
              addIndex: 0,
            });
          });
      });
  }

  public orderItemsHasError(): boolean {
    return this.orderItems.length < 1;
  }

  public rowDelete(params: any): void {
    this.orderItems = this.orderItems.filter(i => i.part !== params.data.part);
  }

  public async submit(): Promise<void> {
    this.submitAttempted = true;
    this._validateForm();

    if (this.formSubmitted || !this.form.valid)
      return;

    this.formSubmitted = true;

    // set item indexing
    let idx = 1;
    this.orderItems.forEach(i => {
      i.line = idx;
      idx++;
    });

    // build request
    const request = new SubmitOrderRequest().deserialize({
      channel: this.f.channel.value,
      comments: this.f.comments.value,
      customer: this.f.selectedCustomer.value.customer,
      orderDate: new Date(this.orderDate),
      ownerContact: this.f.ownerContact.value,
      ownerEmail: this.f.ownerEmail.value,
      ownerPhone: this.f.ownerPhone.value,
      partial: this.f.partialShipment.value,
      po: this.f.poNumber.value,
      requiredDate: new Date(this.requiredDate),
      shipTo: this.f.selectedShipTo.value,
      shipvia: this.f.selectedShipVia.value,
      submittedBy: `${this.identityService.userFullName}`,
      webUser: this.isDealer
        ? 'CWC'
        : 'CWCI',

      orderItems: this.orderItems
    });

    // submit
    this.orderService.submitOrder$(request)
      .subscribe(response => {
        if (response.status == 'SUCCESS')
          Swal.fire('Thank you for your Order!', AppAlerts.OrderEntry.success(response.salesOrder), 'success')
            .then(() => {
              this.submitAttempted = false;
              this.formSubmitted = false;
              window.location.reload();
            });
        else {
          Swal.fire('Something went wrong', AppAlerts.OrderEntry.failure, 'error')
            .then(() => {
              this.submitAttempted = false;
              this.formSubmitted = false;
            })
        }
      });
  }

}
